@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: url("montserrat-latin-100-normal.d5050453.woff2") format("woff2"), url("montserrat-latin-100-normal.22b98dc1.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: url("montserrat-latin-200-normal.6b09d2a8.woff2") format("woff2"), url("montserrat-latin-200-normal.2b087972.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("montserrat-latin-300-normal.158e61d8.woff2") format("woff2"), url("montserrat-latin-300-normal.eb1781eb.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("montserrat-latin-400-normal.f027985f.woff2") format("woff2"), url("montserrat-latin-400-normal.265f4e61.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("montserrat-latin-500-normal.df10de8d.woff2") format("woff2"), url("montserrat-latin-500-normal.3a13f813.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("montserrat-latin-600-normal.fbb08785.woff2") format("woff2"), url("montserrat-latin-600-normal.7a0e5ab5.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("montserrat-latin-700-normal.7f5cc6e4.woff2") format("woff2"), url("montserrat-latin-700-normal.71afe628.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("montserrat-latin-800-normal.0cc894c8.woff2") format("woff2"), url("montserrat-latin-800-normal.6781f29f.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url("montserrat-latin-900-normal.ba4e2007.woff2") format("woff2"), url("montserrat-latin-900-normal.945b627c.woff") format("woff");
}
/*# sourceMappingURL=index.b96f7383.css.map */
